<template>
    <div class="page-page">
        <div class="container">
            <div class="page-title">{{ title }}</div>
            <div class="page-container">
                <div v-html="content"></div>
            </div>
        </div>
    </div>
    <div class="page-bg">
        <img class="bg" src="@/assets/images/product-banner.png" />
    </div>
</template>

<script>
export default {
    name: 'Page',
    data(){
        return {
            title: '',
            content: ''
        }
    },
    watch: {
        $route(){
            this.getPage(this.$route.params.id);
        }
    },
    mounted(){
        this.getPage(this.$route.params.id);
    },
    methods: {
        getPage(cat_id){
            this.$api.page(cat_id).then(res => {
                this.content = res.data.content;
                this.title = res.data.category.title;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-page{
    @extend .flex-column;
    position: relative;
    z-index: 99;
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    .page-title{
        @extend .flex-column-center;
        height: 300px;
        font-family: 'cusTitle';
        font-size: 48px;
        color: $orange;
    }
    .page-container{
        padding: 20px;
        margin: -30px 0 50px;
        border-radius: $radius-big;
        background-color: $white;
        box-shadow: $shadow-default;
    }
}
</style>